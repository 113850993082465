import * as React from "react";
import Layout from "../../components/Layout";
import { Link } from "gatsby";
// TODO: use a different image
import brand_header_desktop from "../../images/2. Photography/Photography_Main.jpeg";
import DigitasFiftyFifty from "../../ui-blocks/digitas-fifty-fifty";
import empty_png from "../../images/logo/empty.png";

import PrevNextNavFooter from "../../ui-blocks/PrevNextNavFooter/PrevNextNavFooter";

const Photography = (props) => {
  return (
    <Layout>
      <div
        className="page-header image-header"
        id="overview"
        data-navindex="1"
        style={{ backgroundColor: "rgb(255, 255, 255)", height: "846px" }}
      >
        <div className="content-flex">
          <div
            className="copy text-behind-image text-behind-image-container"
            style={{ color: "#fff" }}
          >
            <p>
              Our photography reflects who we are as a brand; our personality,
              <br /> our beliefs and our products. Photography should aim to be
              fresh, <br />
              optimistic, relatable. It's another way to connect with our New
              Zealanders and like everything we do, is a representation of{" "}
              <em>Let’s&#8239;Go Places</em>.
            </p>
          </div>
        </div>

        <div className="background" style={{ backgroundColor: "#fff" }}>
          <div style={{ height: "100%" }} className="lazyload-placeholder">
            <img
              alt=""
              src={brand_header_desktop}
              alt="Brand Header (desktop)"
            />
          </div>
        </div>
      </div>

      <section
        className="component Section darker-background spaced-section"
        style={{ paddingBottom: 0 }}
        id="people"
      >
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy">
              Here are some broad guidelines for capturing places, people and
              products. Undoubtedly from time to time campaigns will require
              specific photography considerations, in these cases the spirit of
              the following principles and our tone should be applied.
            </div>
          </div>
        </div>

        <div className="section-header" style={{ padding: "60px 0 24px 0" }}>
          <div className="container">
            <h2 className="ui header">People</h2>
          </div>
        </div>

        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              We will often photograph people as part of campaigns. We always
              look for people who feel authentic and natural. Whether they're in
              a lifestyle or story moment, or are posed and looking directly at
              the camera, we always aim for genuine and natural performance and
              expression.
            </div>

            <div className="copy spaced-p">
              We would look to use natural light or create the feeling of
              natural light sources. People cast for campaigns should provide
              personality, character and humanity. They help us deliver our own
              personality, tone and manner through visual storytelling. We
              should always look to reflect the diversity of modern New Zealand
              through our campaigns.
            </div>
          </div>
        </div>

        {/* begin T-image block */}
        <div style={{ background: "#fafafa", paddingBottom: "80px" }}>
          <div className="image">
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <img
                  alt=""
                  src={empty_png}
                  style={{
                    height: "500px",
                    width: "1140px",
                    margin: "0 auto 0 auto",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="image" style={{ paddingTop: "16px" }}>
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <div
                  style={{ margin: "auto", width: "1140px", display: "flex" }}
                >
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={empty_png}
                      style={{ height: "500px", paddingRight: "8px" }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={empty_png}
                      style={{ height: "500px", paddingLeft: "8px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end T-image block */}
      </section>

      <section className="component Section darker-background" id="place">
        <div className="section-header">
          <div className="container">
            <h2 className="ui header">Place</h2>
          </div>
        </div>
        <div className="blog-content">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              Chosen locations should look to capture New Zealandness. Whether
              that place is the raw beauty of our big landscapes, the green of
              our native bush, the light of our coastal beaches. Or conversely,
              if it's the charm of a small town, the excitement of a city
              street, or the colour of a muralled brick wall. Making all our
              marketing communications feel of New Zealand is what photography
              can help us do.
            </div>

            <div className="copy spaced-p">
              We always aim for natural scenes and avoid making cars in
              environments look too retouched or cut and pasted in to scenes. We
              avoid having vehicles in places that suggest any disrespect for
              the environment and protected or sacred sites. We never disregard
              safety or driving codes.
            </div>
          </div>
        </div>

        {/* begin T-image block */}
        <div style={{ background: "#fafafa", paddingBottom: "80px" }}>
          <div className="image">
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <img
                  alt=""
                  src={empty_png}
                  style={{
                    height: "500px",
                    width: "1140px",
                    margin: "0 auto 0 auto",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="image" style={{ paddingTop: "16px" }}>
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <div
                  style={{ margin: "auto", width: "1140px", display: "flex" }}
                >
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={empty_png}
                      style={{ height: "500px", paddingRight: "8px" }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={empty_png}
                      style={{ height: "500px", paddingLeft: "8px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end T-image block */}
      </section>

      <section className="component Section darker-background" id="product">
        <div className="section-header">
          <div className="container">
            <h2 className="ui header">Product</h2>
          </div>
        </div>
        <div className="blog-content">
          <div className="container onecolumn">
            <div className="copy">
              Our vehicles (and other products) are shot in environments or
              against white backgrounds for clear-cutting. Highlighting the
              driver's side is often preferred, otherwise shoot the angle that
              best conveys the unique attributes of the vehicle. Avoid lens
              distortion, hotspots and ensure an accurate representation of the
              vehicle colour is captured.
            </div>
          </div>
        </div>

        {/* begin 5-image block */}
        <div style={{ background: "#fafafa", paddingBottom: "80px" }}>
          <div className="image">
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <img
                  alt=""
                  src={empty_png}
                  style={{
                    height: "500px",
                    width: "1140px",
                    margin: "0 auto 0 auto",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="image" style={{ paddingTop: "16px" }}>
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <div
                  style={{ margin: "auto", width: "1140px", display: "flex" }}
                >
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={empty_png}
                      style={{ height: "500px", paddingRight: "8px" }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={empty_png}
                      style={{ height: "500px", paddingLeft: "8px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="image" style={{ paddingTop: "16px" }}>
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <div
                  style={{ margin: "auto", width: "1140px", display: "flex" }}
                >
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={empty_png}
                      style={{ height: "500px", paddingRight: "8px" }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={empty_png}
                      style={{ height: "500px", paddingLeft: "8px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end 5-image block */}
      </section>

      {/* <div>
                <Link to='/brandguidelines/Logo' className='up-next'  data-label='Typography'>
                    <div className='link-section'>
                        <h4 className='ui header next'>Next</h4>
                        <h1 className='ui header section-name'>Logos</h1>
                    </div>
                </Link>
            </div> */}
      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Typography"
        previousUrl="/brandguidelines/typography"
        nextText="Next"
        nextHeader="Colours"
        nextUrl="/brandguidelines/colours"
      />
    </Layout>
  );
};

export default Photography;
